import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    if (window.location.hash && (document.querySelector(window.location.hash))) {
      const element = document.querySelector(window.location.hash)
      new bootstrap.Tab(element)
      bootstrap.Tab.getInstance(element).show()
    }
  }
}
