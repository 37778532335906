import * as Turbo from "@hotwired/turbo"
import Toast from "utils/toast"
window.bootstrap = require("bootstrap/dist/js/bootstrap.bundle")
import "controllers"

window.Turbo = Turbo
// // import 'lazysizes'
// // import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'
document.addEventListener("turbo:load", function() {
  // Register VirtualPageview to GTM
  window.dataLayer = window.dataLayer || []
  dataLayer.push({ 'event': 'VirtualPageview' })

  // Register PageRead to GTM
  setTimeout(() => {
    window.dataLayer = window.dataLayer || []
    dataLayer.push({ 'event': 'PageRead' })
  }, 20000)

  if (!(document.querySelector('body.homepage'))) {
    const background = document.querySelector("meta[name='header-background']").content
    document.querySelector(".header-wrapper").style.background = `linear-gradient(0deg, rgba(21,21,20,1) 0%, rgba(21,21,20,0.8) 5%, rgba(21,21,20,0.25) 20%, rgba(21,21,20,0.25) 80%, rgba(21,21,20,0.8) 100%), url('${background}') no-repeat center`
  }
});

document.addEventListener("turbo:before-cache", function() {
  document.getElementById('offcanvas').classList.remove('show')
  if (document.contains(document.querySelector(".modal-backdrop"))) {
    document.querySelector(".modal-backdrop").remove();
  }
})

