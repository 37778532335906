import { Controller } from "stimulus"

import SwiperCore, { Autoplay } from 'swiper'
SwiperCore.use([Autoplay])
window.Swiper = SwiperCore

export default class extends Controller {
  connect() {
    const swiper = new Swiper(this.element, {
      slidesPerView: 'auto',
      loop: true,
      autoplay: {
        delay: 2500
      }
    });
  }
}
